
























































import mixins from 'vue-typed-mixins'
import {
  grammarStore,
  snackbarStore,
} from '@/store'
import GrammarCreateEditFields from '@/components/Grammar/CreateEditFields.vue'
import {
  newGrammar,
} from '@/util/grammar'
import {
  form,
} from '@/mixins'

export default mixins(form)
  .extend({
    components: {
      GrammarCreateEditFields,
    },
    data: () => ({
      loadingCreate: false,
      newGrammar: newGrammar(),
    }),
    methods: {
      async create () {
        if (!this.validateForm()) return
        try {
          this.loadingCreate = true
          await grammarStore.create(this.newGrammar)
          this.$router.go(-1)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Grammar successfully created`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating grammar`,
          })
        }
        this.loadingCreate = false
      },
      async reset () {
        this.newGrammar = newGrammar()
      },
    },
  })
